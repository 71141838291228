.table td,
.table th {
  overflow: hidden;
}

p {
  margin-bottom: 0 !important;
}

.pcoded-header .dropdown .dropdown-toggle:after {
  display: none !important;
}
